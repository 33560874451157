<template>
    <div class="edu-course">
        <div style="position:relative">
        <img class="bannerImg" src="../assets/images/eduCourse/banner.png" alt="">
        <div class="banner">
            <p >人工智能实训课程</p>
            <p>国产人工智能教育科研解决方案</p>
        </div>    
        </div>
        <div class="paragraphs">
            <div class="introduction">
                <div class="left"  :style="{ 'backgroundImage': `url(${pages.introduction.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'3.5rem'}">
                </div>
                <div class="right">
                    <p class="content">{{pages.introduction.content}}</p>
                    <div class="subContent">
                        <div v-for="(item,index) of pages.introduction.subContent" :key="index">
                            <p class="title">{{item.title}}</p>
                            <p class="content">{{item.content}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="courses">
                <div>
                    <div class="title">{{pages.courses.title}}</div>
                    <div class="subContent">
                        <div v-for="(item,index) of pages.courses.subContent" :key="index">
                            <div :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'1.5rem'}"></div>
                            <div class="subTitle">{{item.subtitle}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cases">
                <div>
                    <div class="title">{{pages.cases.title}}</div> 
                                <div class="subContent">
                                    <div v-for="(item,index) of pages.cases.subContent" :key="index">
                                        <div :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'2.5rem'}">
                                            <p class="subTitle"> {{item.subtitle}}</p>  
                                        </div>
                                        <div class="content">{{item.content}}</div>
                                    </div>
                                </div>
                </div>
              
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import productData from '@/assets/js/eduProduct.js'
export default {
    name: 'eduCourse',
    data () {
        return {
            isActive: 1,
            pages:{
                introduction:{
                    content:'南京智算中心已面向国内多所院校合作展开智慧教育深化合作。通过多种教研合作模式，为院校提供了包括设备、课件、在线课程等教育资源，形成长期与高校学院发展的智慧教学生态训练营，引导学生在真实的环境中了解行业背景，深度开发学生的在大数据，人工智能开发过程中的实践能力。',
                    subContent:[
                        {title:'20+',content:'专业的AI课程培训导师'},
                        {title:'10+',content:'完善的课程培训体系'},
                         {title:'50+',content:'培训项目超过50+'}
                    ],
                    url:require('@/assets/images/eduCourse/p1_1.png')
                },
                courses:{
                    title:'课程内容',
                    subContent:[
                        {subtitle:'专业化实训教材',url:require('@/assets/images/eduCourse/p2_1.png')},
                        {subtitle:'智算中心算力云平台',url:require('@/assets/images/eduCourse/p2_2.png')},
                        {subtitle:'云边一体实训硬件',url:require('@/assets/images/eduCourse/p2_3.png')},
                        {subtitle:'标准化实训项目',url:require('@/assets/images/eduCourse/p2_4.png')},
                    ]
                },
                cases:{
                    title:'优秀案例',
                    subContent:[
                        {subtitle:'西交利物浦',content:'南京智算中心多名高级研发讲师来到西交利物浦苏州校区，面向近百名高校学生，开展了以《面向电商大数据的人工智能搜索推荐系统的研究与实现》为主题的三周实验课程。',url:require('@/assets/images/eduCourse/p3_1.png')},
                        {subtitle:'洛阳理工',content:'根据国内外学术、产业界发展趋势，南京智算中心选择人工智能芯片为核心计算单元，并搭配其他异构计算资源，帮助洛阳理工构筑异构智能计算平台。',url:require('@/assets/images/eduCourse/p3_2.png')},
                        {subtitle:'南京工程学院',content:'南京智算中心（中科逆熵）与南京工程学院签署了电网人工智能共建实验室战略合作，共同探索助力人工智能在电网领域的基础研究和生态构建，促进人工智能技术需求和学院科研与学生专业能力提升。',url:require('@/assets/images/eduCourse/p3_3.png')},
                    ]
                }
            }
        }
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods: {
       
    }

}
</script>
<style lang="scss" scoped>
.edu-course {
    .banner{
        left:10%;
        transform: translateY(-60%);
        p:nth-child(1){
         display: inline-block;
         line-height: 0.5rem;
         border-bottom: 1px solid #DDE9F9;   
        }
       p:nth-child(2){
       font-size: 0.18rem;
       margin-top: 0.05rem;
       display: block;
       border-bottom: none;
       }
    }
    .paragraphs{
        >div{
          width: 100%;
        }
         .title{
               text-align: center;
           }
        .subTitle{
                font-size: 0.2rem;
                // font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 0.4rem;
                margin-top: 0.2rem;
                text-align: center;
           }
               .content{
                font-size: 0.16rem;
                // font-family: Microsoft YaHei;
                font-weight: 400;
                color: #808080;
                line-height: 0.3rem;
                text-indent: 2em;
             }
                .subContent{
                    display: flex;
                    justify-content: space-between;
                     margin-top: 0.5rem;
                }
            .introduction{
                width:100%;
                display: flex;
                >div{
                    width: 50%;
                }
                .right{
                    padding: 0.5rem 2rem 0 0.5rem;
                    // padding-right: 2rem;
                    background: #F5F5F5;
                    // display: flex;
                    
                }
            .subContent{
                >div{
                    width: 25%;
                    height: 1rem;
                    background: #FFFFFF;
                    border: 0.05rem solid #0061E3;
                    text-align: center;
                    padding: 0 0.1rem;
                    
                }
                .title{
                    font-size: 0.4rem;
                    color:#0061E3;
                }
                .content{
                  color:#0061E3;
                  border-bottom: 1px solid #0061E3;
                  border-top: 1px solid #0061E3;
                  text-indent: 0;
                }
            }
        }
       .cases{
           background-color: #F5F5F5;
           padding: 0.5rem 0;
           >div{
               width: 70%;
               margin: auto;
           }
          .subContent{
              >div{
                  width: 30%;
                  border-radius: 4px;
              }
              .subTitle{
                   writing-mode: vertical-lr;
                   writing-mode: tb-lr;
                   letter-spacing:0.05rem;
                   height: 100%;
                   width: 0.8rem;
                   text-align: center;
                   font-size: 0.2rem;
                   line-height:0.8rem;
                   color: #FFFFFF;
                   background-color: #0061E3;
                   opacity: 0.7;
              }
              .content{
                  height:1.8rem ;
                  padding: 0.2rem 0.2rem 0;
                  background-color: #FFFFFF;
              }
              .content:hover{
                  border:1px solid #0061E3;
              }
          }
       }
       .courses{
           >div{
               width: 70%;
               margin: auto;
           }
           .subContent{
               >div{
                   width: 22%;
                   box-sizing: border-box;
                   background-color: #F5F5F5;
                   padding: 0.3rem;
               }
           }
       }
    }
}

</style>
